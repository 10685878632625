export function initWorksFilter() {
  const genreFilters = document.querySelectorAll(".genre-filter");
  const yearFilters = document.querySelectorAll(".year-filter");
  const worksContainer = document.querySelector(".page-works-container");
  const groups = document.querySelectorAll(".group");
  const searchInput = document.getElementById("work-search-input");
  const clearSearchButton = document.getElementById("clear-search-button");
  const resetButton = document.getElementById("reset-filters-button");

  let activeGenre = "all";
  let activeYear = "all";
  let searchQuery = "";

  function normalizeText(text, forSearch = false) {
    if (forSearch && text.includes("ё")) {
      return text.toLowerCase();
    }
    return text.toLowerCase().replace(/ё/g, "е");
  }

  function filterWorks() {
    worksContainer.classList.add("fading");

    setTimeout(() => {
      let visibleWorks = 0;
      const normalizedSearchQuery = normalizeText(searchQuery, true);

      groups.forEach((group) => {
        const groupGenre = group.dataset.genres;
        const groupYears = group.dataset.year.split(" ");
        const originalGroupTitle =
          group.querySelector(".page-works_title").textContent;
        const normalizedGroupTitle = normalizeText(originalGroupTitle);

        const genreMatch = activeGenre === "all" || groupGenre === activeGenre;
        const yearMatch =
          activeYear === "all" || groupYears.includes(activeYear);

        let searchMatch;
        if (searchQuery.includes("ё")) {
          // Если в запросе есть "ё", ищем точное совпадение
          searchMatch = originalGroupTitle
            .toLowerCase()
            .includes(normalizedSearchQuery);
        } else {
          // Если в запросе нет "ё", ищем и "е", и "ё"
          searchMatch = normalizedGroupTitle.includes(normalizedSearchQuery);
        }

        if (genreMatch && yearMatch && searchMatch) {
          group.style.display = "";
          visibleWorks++;
        } else {
          group.style.display = "none";
        }
      });

      resetButton.style.display = visibleWorks === 0 ? "block" : "none";

      worksContainer.classList.remove("fading");
    }, 300);
  }

  function resetFilters() {
    activeGenre = "all";
    activeYear = "all";
    searchQuery = "";
    searchInput.value = "";
    genreFilters.forEach((f) => f.classList.remove("active"));
    yearFilters.forEach((f) => f.classList.remove("active"));
    document
      .querySelector('.genre-filter[data-genre="all"]')
      .classList.add("active");
    document
      .querySelector('.year-filter[data-year="all"]')
      .classList.add("active");
    updateClearButtonVisibility();
    filterWorks();
  }

  function updateClearButtonVisibility() {
    clearSearchButton.style.display = searchInput.value ? "block" : "none";
  }

  genreFilters.forEach((filter) => {
    filter.addEventListener("click", (e) => {
      e.preventDefault();
      activeGenre = filter.dataset.genre;
      genreFilters.forEach((f) => f.classList.remove("active"));
      filter.classList.add("active");
      filterWorks();
    });
  });

  yearFilters.forEach((filter) => {
    filter.addEventListener("click", (e) => {
      e.preventDefault();
      activeYear = filter.dataset.year;
      yearFilters.forEach((f) => f.classList.remove("active"));
      filter.classList.add("active");
      filterWorks();
    });
  });

  let searchTimeout;
  searchInput.addEventListener("input", (e) => {
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      searchQuery = e.target.value;
      updateClearButtonVisibility();
      filterWorks();
    }, 300);
  });

  clearSearchButton.addEventListener("click", () => {
    searchInput.value = "";
    searchQuery = "";
    updateClearButtonVisibility();
    filterWorks();
  });

  resetButton.addEventListener("click", resetFilters);

  groups.forEach((group) => {
    group.style.display = "";
  });

  updateClearButtonVisibility();
}
