// Функция управления модальным окном
export function openModal(
  modalSelector = "#modal",
  contentSelector = ".modal-content",
  closeSelector = ".modal-close",
  activeSelector = "modal-is-open",
  options = {},
) {
  console.log("openModal called with:", {
    modalSelector,
    contentSelector,
    closeSelector,
    activeSelector,
    options,
  });

  const { linksInsideCloseModal = false } = options;
  const body = document.body;
  const modal = document.querySelector(modalSelector);

  console.log("Modal element:", modal);

  if (!modal) {
    console.error(`Modal with selector "${modalSelector}" not found`);
    return;
  }

  const modalContent = modal.querySelector(contentSelector);
  const modalClose = modal.querySelector(closeSelector);
  const modalDismisses = modal.querySelectorAll("[data-dismiss]");

  console.log("Modal content:", modalContent);
  console.log("Modal close button:", modalClose);
  console.log("Modal dismiss elements:", modalDismisses);

  function openModalWindow() {
    console.log("Opening modal window");

    const scrollWidth =
      window.innerWidth - document.documentElement.clientWidth;
    if (scrollWidth > 0) {
      body.style.paddingRight = `${scrollWidth}px`;
    }
    body.style.overflow = "hidden";

    modal.style.display = "block";
    modal.classList.add(activeSelector);
    body.classList.add(activeSelector);

    console.log("Active class added to modal and body");

    if (modalClose) {
      modalClose.addEventListener("click", closeModal);
    }
    if (linksInsideCloseModal && modalContent) {
      modalContent.querySelectorAll("a").forEach((link) => {
        link.addEventListener("click", closeModal);
      });
    }
    modalDismisses.forEach((dismiss) => {
      dismiss.addEventListener("click", closeModal);
    });
    modal.addEventListener("click", closeModal);
    if (modalContent) {
      modalContent.addEventListener("click", stopPropagation);
    }
    document.addEventListener("keydown", handleEscKey);
  }

  function closeModal() {
    console.log("Closing modal window");

    modal.style.display = "none";
    modal.classList.remove(activeSelector);
    body.style.overflow = "";
    body.style.paddingRight = "";
    body.classList.remove(activeSelector);

    if (modalClose) {
      modalClose.removeEventListener("click", closeModal);
    }
    if (linksInsideCloseModal && modalContent) {
      modalContent.querySelectorAll("a").forEach((link) => {
        link.removeEventListener("click", closeModal);
      });
    }
    modalDismisses.forEach((dismiss) => {
      dismiss.removeEventListener("click", closeModal);
    });
    modal.removeEventListener("click", closeModal);
    if (modalContent) {
      modalContent.removeEventListener("click", stopPropagation);
    }
    document.removeEventListener("keydown", handleEscKey);

    console.log("Modal closed");
  }

  function stopPropagation(event) {
    event.stopPropagation();
  }

  function handleEscKey(event) {
    if (event.key === "Escape") {
      closeModal();
    }
  }

  // Изменяем логику проверки состояния модального окна
  if (modal.classList.contains(activeSelector)) {
    console.log("Modal was already open, closing it");
    closeModal();
  } else {
    console.log("Opening new modal");
    openModalWindow();
  }

  return closeModal;
}
